import { eventTracker } from "@/utils/event_tracker";
import * as eventNames from "@/constants/event_names";
import { eventTrackerHandler as evt } from "@/app/DeferredFeatures/features/event_tracker/event-tracker-handler";

export const tracker = {
  install: function (Vue) {
    Vue.prototype.$eventTracker = eventTracker;
    Vue.prototype.$eventNames = eventNames;
    Vue.prototype.$evt = evt;
  },
};
