import "@/utils/webpack_public_path";
const App = () =>
  import(/* webpackChunkName: "App" */ "@/app/PasswordlessLogin/Main.vue");
const AppMobile = () =>
  import(
    /* webpackChunkName: "AppMobile" */ "@/app/PasswordlessLogin/Main.mobile.vue"
  );
import Vue from "vue";

import { accountsRuntimeContext } from "@/plugins/accounts";
import { tracker } from "@/plugins/tracker";
import { snackbar } from "@/plugins/snackbar";
import { webpSupport } from "@/plugins/webp-support";

const SELECTOR = "#app-login";

Vue.use(accountsRuntimeContext, { wrapper: SELECTOR });
Vue.use(tracker);
Vue.use(snackbar);
Vue.use(webpSupport);

const app = new Vue({
  render(h) {
    const { isSmallScreen } = this.$accounts;
    return h(!isSmallScreen ? App : AppMobile);
  },
});

app.$mount(SELECTOR);
