export function eventTracker(data) {
  const eventBus = global["__EVENT_BUS__"];
  const deferredFeaturesPromise = global["DEFERRED_FEATURES_P"];
  const clickEventName = "event-tracker";

  deferredFeaturesPromise.then(() => {
    eventBus.emit(clickEventName, {
      tag: "internal-tracker",
      data,
    });
  });
}
