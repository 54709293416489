export const snackbar = {
  install: function (Vue) {
    Vue.prototype.$snackbar = function (component, params) {
      const { type, message, actionText, isError } = params;

      component.type = type || "general";
      component.message = message || null;
      component.actionText = actionText || null;
      component.isShow = true;
      component.isError = isError || false;
    };
  },
};
